



























































































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Login extends Vue {
  email = "";
  password = "";
  error = false;
  buttonText = "Login";
  buttonDisable = false;

  mounted() {
    this.$store.dispatch("auth/logout");
  }

  login() {
    this.error = false;
    this.buttonText = "Wait...";
    this.buttonDisable = true;
    this.$store.dispatch("auth/login", { email: this.email, password: this.password }).then(
      () => {
        this.$router.push({ name: "Home" });
      },
      () => {
        this.error = true;
        this.buttonText = "Login";
        this.buttonDisable = false;
      }
    );
  }
}
